body{
   height: 100%;
   overflow: hidden;
   background-color: #000;
   margin: 0;
   padding: 0;
   font-family: 'Mohave', sans-serif;
}
img {
    width: 100%;
    transition: all 0.5s ease-out;
}
.clear {
    clear: both;
}
.content {
    width: 80%;
    margin: 0 auto;
    padding: 40px 0;
}
#root{
    width: 100%;
    height: 100%;
}
#container{
    width: 100%;
    height: 100%;
    background-image: url("../public/img/voxi-slide-1.jpg");
    background-size: contain;
    position: fixed;
    z-index: 1;
}
.content.programs {
    width: 100%;
    padding: 0;
    margin: 0;
}
.item_programs {
    position: relative;
    width: 33.33%;
    float: left;
    transition: all 0.5s ease-out;
}
.item_programs img:hover {
    opacity: 0.4;
    transition: all 0.5s ease-out;
}
.item_programs_content {
    background: url("https://radiovoxi.com/img/bg_programs.jpg") no-repeat;
    background-size: cover;
    top: 0;
    height: 100%;
    width: 100%;
    color: #FFF;
    transition: all 0.5s ease-out;
    z-index: 1;
    display: none;
}
.item_programs_content a {
    color: #FFF;
}
.item_programs_content p {
    padding: 10%;
}
#programs {
    width: 100%;
}
.programs_text{
    display: none;
}
.programs h1{
    background-color: #dde72e;
    color: #4bcaaf;
    text-align: center;
    font-size: 70px;
    font-weight: 600;
    width: 100%;
    margin: 0;
    padding: 20px 0 10px 0;
}
#header{
    background-color: #000;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}
#player{
    width: auto;
    background: #000;
    float: left;
    border-right: solid 1px #333;
}
#logo {
    background-color: #fff;
    width: 139px;
    float: left;
}
#social{
    width: 284px;
    float: left;
    border-right: solid 1px #333;
    border-left: solid 1px #333;
    text-align: center;
}
.social_red {
    width: 8%;
    display: inline-block;
    margin: 7% 2%;
    transition: all 0.5s ease-out;
    cursor: pointer;
}
.social_red:hover {
    opacity: 0.4;
    transition: all 0.5s ease-out;
}
#club{
    text-align: center;
    width: 10%;
    float: left;
    border-right: solid 1px #333;
}
#club span {
    display: block;
    color: #fff;
    font-size: 25px;
    line-height: 66px;
    letter-spacing: 1px;
}
#play_state{
    display: none;
}
#audio{
    display: none;
}
#play, #play_state{
    width: 45px;
    margin: 8.3px 20px;
}
#mute, #unmute{
    width: 30px;
    margin: 15.2px 20px;
}
#mute{
    display: none;
}
#controls{
    float: left;
}
#sound{
    float: right;
}
#footer{
    background-color: #000;
}
#logo_footer{
    width: 150px;
}
#copy {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    padding: 20px;
    float: left;
}
#footer .social_red {
    margin: 5% 2%;
    transition: all 0.5s ease-out;
    cursor: pointer;
}
#footer #social {
    display: none;
    width: 300px;
    float: left;
    border-right: none;
    border-left: none;
    text-align: left;
}
#separate_footer{
    width: 100%;
    clear: both;
    border-top: solid 1px #333;
}
#song{
    color: #fff;
    font-size: 12px;
    float: left;
    margin: 26.2px 5px;
}
.slick-prev, .slick-next {
    display: none !important;
}

input[type='range'] {
    overflow: hidden;
    width: 60px;
    -webkit-appearance: none;
    background-color: #000;
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #fff;
    margin-top: -1px;
  }
  
  input[type='range']::-webkit-slider-thumb {
    width: 15px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #888;
    box-shadow: -60px 0 0 60px #fff;
  }

#volume {
    float: left;
    margin: 22px 0px 22px 15px;
}
.volume{
    width: 60px;
}
@keyframes live {
    from {}
    to {filter: grayscale(100%);}
}
#live {
    width: 60px;
    height: auto;
    position: absolute;
    top:0;
    right: 0;
    animation-name: live;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    margin: 18px;
    display: none;
}
#live.live{
    display: block;
}
.new{
    float: left;
    width: 21%;
    margin: 2%;
}
#novedades{
    background-color: #6449fa;
    position: relative;
}
#novedades img {
    transition: all 0.5s ease-out;
}
#novedades img:hover{
    filter: grayscale(100%);
    opacity: 0.8;
    transition: all 0.5s ease-out;
}
#novedades h1{
    color: #defd01;
    margin: 0;
    padding: 0 0 40px 0;
    text-align: center;
    font-size: 70px;
    font-weight: 100;
    font-style: italic;
}
#loader{
    margin-top: 67px;
    background-color: #040204;
    height: 100%;
    width: 100%;
    top:0;
    position: fixed;
    z-index: 10;
}
#loader img{
    height: auto;
    width: 227px;
    position: absolute;
    top:50%;
    left: 50%;
    margin-left: -113.5px;
    margin-top: -59px;
}
.escuchar, .escuchar_close {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #fff;
    font-size: 16px;
    border: solid 1px #fff;
    padding: 5px 15px;
    border-radius: 30px;
    margin: 25px 20px;
    cursor: pointer;
    z-index: 2;
}
.escuchar_close{
    display: none;
}
.spotify {
    border: none;
    width: 30%;
    display: block;
    float: left;
    height: 232px;
    margin-top: 4vw;
    background: #000;
    padding: 10px;
}
.item_programs_text{
    float: left;
    width: 30%;
}
.item_programs_content.active {
   display: block;
   transition: all 0.5s ease-out;
}
.item_programs.active {
    height: 70vw;
}
.escuchar.active {
    display: none;
}
.escuchar_close.active {
    display: block;
}
video {
    position: fixed;
    width: 100%;
    height: 56.6vw;
}
video.phone {
    height: 100%;
}
.powerby{
    width: 100px;
    position: fixed;
    z-index: 2;
    bottom: 0;
    right: 0;
}

@media only screen and (max-width: 600px) {
    .spotify {
        width: 96%;
        float: none;
        padding: 2%;
    }
    .item_programs_text {
        float: none;
        width: 100%;
    }
    .item_programs_content{
        overflow-x: auto;
    }
    .new{
        width: 96%;
    }
    #novedades h1, .programs h1{
        font-size: 50px;
    }
    .item_programs {
        width: 100%;
        float: none;
    }
    #player {
        border-right: none;
        width: 100%;
    }
    #header #social {
       display: none;
    }
    #logo_footer{
        display: none;
    }
    #separate_footer{
        border-top: none;
    }
    #footer #social {
        display: block;
        margin: 0 2% 3% 2%;
    }
    #footer .social_red {
        margin: 0 2%;
    }
    #controls {
        float: none;
        position: absolute;
        left: 139px;
        top: 0;
    }
    #song {
        float: none;
        margin: 25px 0;
        width: 100%;
        text-align: center;
        clear: both;
    }
    #volume {
        display: none;
        float: none;
        left: 224px;
        margin: 22px 0 22px 0px;
        position: absolute;
        top: 0;
    }
    #sound {
        float: none;
        position: absolute;
        top: 0;
        left: 220px;
    }
  }